<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('user_id')">
							{{ translate('user_id') }}
							<sort field="user_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('order_id')">
							{{ translate('order_id') }}
							<sort field="order_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('status')">
							{{ translate('status') }}
							<sort field="status" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('invoice_id')">
							{{ translate('invoice_id') }}
							<sort field="invoice_id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('customer_id')">
							{{ translate('customer_id') }}
							<sort field="customer_id" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_subtotal')">
							{{ translate('subtotal') }}
							<sort field="base_subtotal" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_shipping')">
							{{ translate('shipping') }}
							<sort field="base_shipping" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_tax')">
							{{ translate('us_taxes') }}
							<sort field="base_tax" />
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('duties') }} ({{ translate('non_us') }})
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_total')">
							{{ translate('total') }}
							<sort field="base_total" />
						</th>
						<th
							class="border-top-0 pointer text-right"
							@click="sortByField('base_refunded')">
							{{ translate('refund_amount') }}
							<sort field="base_refunded" />
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('net_sales') }}
						</th>
						<th class="border-top-0">
							{{ translate('shipping_country') }}
						</th>
						<th class="border-top-0">
							{{ translate('shipping_region') }}
						</th>
						<th class="border-top-0">
							{{ translate('shipping_postcode') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('billing_country')">
							{{ translate('billing_country') }}
							<sort field="billing_country" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('billing_region')">
							{{ translate('billing_region') }}
							<sort field="billing_region" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('billing_postcode')">
							{{ translate('billing_postcode') }}
							<sort field="billing_postcode" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('date_added')">
							{{ translate('order_date') }}
							<sort field="date_added" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('submitted_at')">
							{{ translate('registered_in_avalara') }}
							<sort field="submitted_at" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ `${(((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1))}` }}
						</td>
						<td class="text-left">
							<span
								v-if="admin.includes($user.details().type) && item.attributes.user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td class="text-left">
							{{ item.id }}
							<i
								v-if="item.attributes.is_fraud"
								v-b-tooltip.hover
								:title="translate('fraudulent_order')"
								class="fa fa-info-circle"
								style="margin-left:5px; margin-top:2px; color:#f86c6b" />
							<i
								v-if="item.attributes.is_invisible"
								v-b-tooltip.hover
								:title="translate('invisible_order')"
								class="fa fa-eye-slash"
								style="margin-left:5px; margin-top:2px; color:gray" />
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td class="text-left">
							{{ item.attributes.invoice_id }}
						</td>
						<td class="text-left">
							{{ item.attributes.customer_id }}
						</td>
						<td class="text-right">
							{{ item.attributes.subtotal }}
						</td>
						<td class="text-right">
							{{ item.attributes.shipping }}
						</td>
						<td class="text-right font-weight-bold">
							{{ item.attributes.tax }}
						</td>
						<td class="text-right font-weight-bold">
							{{ item.attributes.tax_no_us }}
						</td>
						<td class="text-right">
							{{ item.attributes.total }}
						</td>
						<td class="text-right">
							{{ item.attributes.refunded }}
						</td>
						<td class="text-right">
							{{ item.attributes.net_sales }}
						</td>
						<td class="text-left">
							{{ translate(item.attributes.shipping_country.toLowerCase()) }}
						</td>
						<td class="text-left">
							{{ item.attributes.shipping_region }}
						</td>
						<td class="text-center">
							{{ item.attributes.shipping_postcode }}
						</td>
						<td class="text-left">
							{{ translate(item.attributes.billing_country.toLowerCase()) }}
						</td>
						<td class="text-left">
							{{ item.attributes.billing_region }}
						</td>
						<td class="text-center">
							{{ item.attributes.billing_postcode }}
						</td>
						<td
							class="text-left"
							style="width: 120px">
							{{ item.attributes.date_added ? $moment(item.attributes.date_added).format(dateFormat) : item.attributes.date_added }}
						</td>
						<td
							class="text-left"
							style="width: 120px">
							{{ item.attributes.registered_in_avalara ? $moment(item.attributes.registered_in_avalara.date).format(dateFormat) : item.attributes.registered_in_avalara }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { Report, Grids, Countries } from '@/translations';
import { YMD_FORMAT } from '@/settings/Dates';
import { admin, distributor } from '@/settings/Roles';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import Taxes from '@/util/Report';

export default {
	name: 'TaxesAll',
	messages: [Report, Grids, Countries],
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			taxesAll: new Taxes(),
			dateFormat: YMD_FORMAT,
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			return !!this.taxesAll.data.loading;
		},
		errors() {
			return this.taxesAll.data.errors;
		},
		pagination() {
			return this.taxesAll.data.pagination;
		},
		data() {
			try {
				const { data } = this.taxesAll.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.taxesAll.getAllTaxes();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.taxesAll.getAllTaxes(options);
		},
	},
};
</script>
